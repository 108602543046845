@import "../../Assets/scss/base";

.server_secn {
    min-height:100vh;
    @include flexCenter; 
    .serverImg {
        img {
            max-width: 500px;
            margin: 0 $auto;
            animation: up-down 2s ease-in-out infinite alternate-reverse both;        
        }
    }  
}
@keyframes up-down {
    0% {
      transform: translateY(10px);
    }
  
    100% {
      transform: translateY(-10px);
    }
  }