// _mixin.scss

// @each $aSize, $fSize in (60, 26), (50, 22), (30, 14) {
//   .avtar#{$aSize} {
//     height: #{$aSize}px;
//     width: #{$aSize}px;
//   }
// }
@each $aSize, $fSize in (30),(40),(45), (50),(60), (80), (120), (140), (160) {
  .avtar#{$aSize} {
    height: #{$aSize}px;
    width: #{$aSize}px;
    min-height: #{$aSize}px;
    min-width: #{$aSize}px;
    border-radius: #{$aSize}px;
  }
}

// Circle
@mixin circle($size) {
  width: $size;
  height: $size;
  border-radius: 100%;
}

// Link Color
@mixin linkColor($color) {
  color: $color;
  &:hover, &:active, &:focus {
      color: darken($color, 20%);
  }
}
// Button

@mixin button-bg($color) {
  background: $color;
  border-color: $color;
  &:hover {
    background: lighten($color, 8%);
    border-color: lighten($color, 8%);
    transition: all 0.3s ease;
  }
  &:active, &:focus {
    border-color: darken($color, 8%);
    background: darken($color, 25%);
  }
}

@mixin box-sizing($type) {
	-webkit-box-sizing:$type;
	-moz-box-sizing:$type;
	box-sizing:$type;
}
@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin opacity($opacity) {
	opacity: $opacity;
	filter: alpha(opacity=($opacity * 100));
}

@mixin border-radius($r) {
  border-radius: $r;
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}
 
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  }
  @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin box-shadow($x-axis: 0, $y-axis: 1px, $blur: 2px, $color: $default) {
  box-shadow: $x-axis $y-axis $blur $color;
}
// @include box-shadow(5px, 5px, 5px, #333);
@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

// Scale
@mixin scale($scale) {
	@include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}

// Skew
@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin ($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
	-ms-transform-origin: $origin;
	transform-origin: $origin;
}

// Clear Fix
@mixin clearit {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
    zoom: 1;
}

@mixin vertical-align {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
 
@mixin vh() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
} 

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

/* @include transition(all, 0.2s, ease-in-out); */
@mixin transition($transition-property, $transition-time, $method) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
} 

// position center
@mixin center($position) {
  position: absolute;  
  @if $position == 'vertical' {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  @else if $position == 'horizontal' {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
  }
  @else if $position == 'both' {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
//declare @include center(both);

@mixin button-bg($bg) {
  background: $primary-color;
  &:hover {
    background:darken($bg,8%);
    transition: all 0.3s ease;
  }
  &:active {
    background:darken($bg,25%);
  } 
} 