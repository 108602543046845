// _variable.scss

$none: none;
$block: block;
$inline-block:inline-block;

$relative: relative;
$absolute: absolute;
$fixed: fixed;
$sticky: sticky;
$static: static;

$bottom: bottom;
$center: center;
$right: right;
$left: left;
$top: top;

$inherit: inherit;
$initial: initial;
$unset: unset;
$justify:justify;

$capitalize: capitalize;
$uppercase: uppercase;
$lowercase: lowercase;
$underline: underline;

$visible:visible;
$pointer:pointer;
$hidden: hidden;
$scroll:scroll;
$auto:auto;

$contain: contain;
$cover: cover;

$repeat: repeat;
$no-repeat: no-repeat;

