@import "../../Assets/scss/base";

main,
.main {
  @media (min-width: 1200px) {
    min-height: calc(100vh - 85px - 70px);
  }
}

// main hero banner
.herobanner {
  background: $white-color;
  width: 100%;
  height: 100vh;
  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 100%;
  }
  &_card {
    width: 100%;
    height: 100%;
    position: $relative;
    @include flexCenter;

    &::before {
      position: $absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.25);
    }

    & > img {
      height: 100%;
      width: 100%;
      object-fit: $cover;
    }

    &_box {
      max-width: 900px;
      width: 100%;
      text-align: $center;
      position: $absolute;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      color: var(--white-color);
    }

    &_content {
      @include flexbox;
      @include flex-flow(column);
      @include font-size(20);
      padding: 0 0.5rem;

      h1,
      .h1 {
        color: $white-color;
        text-transform: $capitalize;
      }

      button {
        max-width: 200px;
        margin: 1rem auto;
      }
    }
  }
}

.donateNow {
  animation: zoom-in-zoom-out 2s ease-out infinite;
  transform-origin: center;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.15, 1.15);
  }

  100% {
    transform: scale(1, 1);
  }
}

@-webkit-keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.15, 1.15);
  }

  100% {
    transform: scale(1, 1);
  }
}