// root
:root {
    --base-color: #0A0A0C;    
    --primary-color: #6E6BDE;
    --black-color: #000000;
    --white-color: #ffffff;
    --light-purple: #797795;
    --dark-blue-color: #18125D;
    --primary-color-200: #EAF7FF;
    --primary-color-100: #C6C5F0;
    --pink-color: #DF26E3;
    --red-color-400: #E95A52;
    --grey-bg:#F9F9FB;
    --ice-blue:#FAFFFF;
    --ice-blue-100:#E4F6FA;
   
}

// _colors
$base-color: #0A0A0C;
$light-purple: #797795;

$body-color: #ffffff;

$primary-color: #6E6BDE;
$secondary-color: #0683A0;

$primary-color-100: #FEE7E6;

$white-color: #ffffff;

//dark-blue color
$dark-blue-color: #18125D;


$blue-color-500: #423D7B;
//purple colors
$primary-color-100: #706DE0;
$purple-color-100: #A5A2BF;
$purple-color-200: #AEACFC;
$purple-color-500: #685dd8;
$primary-color-light:#F0EFFE;
$black-color: #000000;

$black-color-700: #000031;
$label-color: #5d596c;


$sky-blue-color: #1DB9FC;
$ice-blue:#FAFFFF;
$ice-blue-100:#E4F6FA;

$grey-color: #cccccc;
$grey-color-100: #a5a3ae;
$grey-color-200: #A1A1C5;
$grey-color-300: #D6D6D6;
$grey-color-400: #CFCEDE;
$grey-color-500: #F8FCFA;
$grey-color-700: #8E8D8D;

$grey-bg:#F9F9FB;

$error-color: #ee7c87;
$red-color: #ff0000;
$danger-color:#FC2626;
$red-color-400: #E95A52;
$red-color-500: #EB5757;

$success-color: #60D108;
$warning-color: #E9C405;
$info-color: #E97205;
$pink-color: #DF26E3;
$orange-color:#F2903F;
$orange-color-100:#FFF9F4;
$transparent: transparent;